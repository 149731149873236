.skills {
    padding: 4rem 0;
    text-align: center;
  }
  
  .section__title {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  
  .section__subtitle {
    font-size: 1.2rem;
    color: #888;
    margin-bottom: 2rem;
  }
  
  /* Container Grid */
  .skills__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  /* Skill Box */
  .skills__content {
    background: #f9f9f9;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .skills__content:hover {
    transform: translateY(-5px);
  }
  
  /* Category Titles */
  .skills__category {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  /* Skill List */
  .skills__list {
    list-style: none;
    padding: 0;
  }
  
  .skills__item {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    color: #555;
    transition: color 0.3s;
  }
  
  .skills__item:hover {
    color: #007bff;
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .skills__container {
      grid-template-columns: 1fr;
    }
  }
  