.contact__container {
    justify-content: center;
    column-gap: 6rem;
    padding-bottom: 3rem;
    grid-template-columns: 1fr;
}

.contact__form {
    margin: 0 auto;
    width: 80%; 
    text-align: center;
}

.contact__form-div {
    position: relative;
    margin-bottom: var(--mb-2);
    height: 4rem;
}

.contact__form-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(0, 0, 0, 0.3);
    background: none;
    color: var(--text-color);
    outline: none;
    border-radius: 0.75rem;
    padding: 1.5rem;
    z-index: 1;
}

.contact__form-tag {
    position: absolute;
    top: -0.75rem;
    left: 1.25rem;
    font-size: var(--smaller-font-size);
    padding: 0.25rem;
    background-color: var(--body-color);
    z-index: 10;
}

.contact__form-area {
    height: 11rem;
}

.contact__form-area textarea {
    resize: none;
}

.contact__form-name {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

/*===== BREAKPOINTS =====*/

/* For larger devices */
@media screen and (max-width: 992px) {
    .contact__container {
        column-gap: 4rem; /* Adjust column gap */
    }

    .contact__card {
        padding: 1rem; /* Adjust padding */
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
    .contact__container {
        column-gap: 2rem; /* Reduce column gap */
    }

    .contact__card {
        padding: 0.5rem; /* Further adjust padding */
    }

    .contact__form-area {
        height: 8rem; /* Adjust textarea height */
    }

    .contact__form-name {
        grid-template-columns: 1fr; /* Stack grid columns */
        gap: 10px; /* Reduce gap */
    }
}

/* For small devices */
@media screen and (max-width: 576px) {
    .contact__container {
        column-gap: 1rem; /* Further reduce column gap */
    }

    .contact__card {
        padding: 0.5rem; /* Adjust padding */
    }

    .contact__form-area {
        height: 6rem; /* Adjust textarea height */
    }

    .contact__form-input {
        padding: 1rem; /* Adjust padding inside input */
    }
}

/* For very small devices */
@media screen and (max-width: 350px) {
    .contact__container {
        column-gap: 0.5rem; /* Minimal column gap */
    }

    .contact__form-area {
        height: 5rem; /* Adjust textarea height */
    }

    .contact__form-input {
        padding: 0.75rem; /* Further adjust padding */
    }
}
